<template>
  <ion-page >
    <ion-header class="white-background-color"></ion-header>
    <ion-content>
      <div class="a-auth full-height">
        <div class="ion-text-center profile">
          <h1>Profile</h1>
          <div class="image-wrapper">
            <img @click="openSponsoreLink('https://aicolympic.org')" src="/assets/images/AICO_eng.jpg" class="cursor-pointer" alt="aico">
            <div class="splitter"></div>
            <img @click="openSponsoreLink('https://www.dragoparis.fr/jeux-olympiques-et-paralympiques-paris-2024')" class="cursor-pointer" src="/assets/images/new/drago.png" alt="drago">
          </div>
          <div class="a-auth__content-wrapper">
            <p>{{ user.username }}</p>
            <p class="username">{{user.email}}</p>
            <div class="small-divider"></div>
            <div class="ion-padding">
              <ion-button class="--default" @click="manageProfile()">Manage</ion-button>
            </div>
            <div class="version">version: {{version}}</div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
  import {mapGetters} from 'vuex'
  import {inject} from "vue"

  export default {
    setup() {
      const ionRouter = inject("navManager")

      return {
        ionRouter,
      }
    },

    data() {
      return {
        version: process.env.VUE_APP_VERSION,
      }
    },
    computed: {
      ...mapGetters('wAuth', ['user']),
    },
    methods: {
      manageProfile() {
        this.ionRouter.navigate({
          routerDirection: "none",
          routerLink: "/app/manage-profile",
        })
      },

      openSponsoreLink(link) {
        window.open(link)
      }
    }
  }
</script>

<style lang="sass" scoped>
.splitter
  width: 2px
  height: 92px
  margin: 5px 0
  border-radius: 100px
  background: rgba(190, 190, 190, .4)

.profile
  h1
    font-size: 26px
    margin: 0
    padding-top: 70px
    font-weight: bold
    color: black
  p
    margin: 0
    padding-bottom: 30px
    color: black
    &:first-child
      padding-bottom: 10px
  .image-wrapper
    display: flex
    justify-content: center
    padding-top: 30px
    img
      width: 49%
      padding: 0 10px
      margin: 5px 0
  .small-divider
    width: 15%
    height: 1px
    margin: 0 auto
    background: black
    position: relative
    top: -1px
  .username
    font-weight: bold
    border-bottom: 1px solid rgba(154, 154, 154, 0.4)



@media only screen and (min-width: 768px)
  img
    max-width: 150px

@media only screen and (max-width: 550px)
  .white-background-color
    background: white
@media only screen and (max-width: 1100px)
  ion-header
    height: 40px
    background: white

.version
  color: rgba(0,0,0, 0.3)
  font-size: 12px
</style>
