
  import {mapGetters} from 'vuex'
  import {inject} from "vue"

  export default {
    setup() {
      const ionRouter = inject("navManager")

      return {
        ionRouter,
      }
    },

    data() {
      return {
        version: process.env.VUE_APP_VERSION,
      }
    },
    computed: {
      ...mapGetters('wAuth', ['user']),
    },
    methods: {
      manageProfile() {
        this.ionRouter.navigate({
          routerDirection: "none",
          routerLink: "/app/manage-profile",
        })
      },

      openSponsoreLink(link) {
        window.open(link)
      }
    }
  }
